import React, { useEffect, useState } from "react";
import axios from "axiosClient";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import Resizer from "react-image-file-resizer";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { useToken } from "auth/useToken";
import Select from "react-select";

const initialValues = {
  username: "",
  password: "",
  role: "",
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  telegramUsername: "",
  gender: "",
  location: "",
  managedCountries: [],
};

const AddUser = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [values, setValues] = useState(initialValues);
  const [errorMessage, setErrorMessage] = useState("");
  const [locations, setLocations] = useState([]);
  const [managingCountries, setManagingCountries] = useState([]);
  const [preview, setPreview] = useState("");
  const [image, setImage] = useState({});
  const [isNew, setIsNew] = useState(
    location.pathname.split("/").slice(-1)[0] === "add"
  );
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useToken();

  const { id } = useParams();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (values.password === "") {
      delete values.password;
    }
    let data = { ...values, image };

    axios
      .post(`${isNew ? "/api/admin/register" : `/api/admin/${id}`}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        if (result.data.success) {
          navigate("/app/users");
        }
      })
      .catch((err) => {
        setErrorMessage(err.response.data);
      });
  };

  const handleDeleteUser = () => {
    axios
      .delete(`/api/admin/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        if (result.data.success) {
          navigate("/app/users");
        }
      });
  };

  useEffect(() => {
    !isNew && setLoading(true);
    !isNew &&
      axios
        .get(`/api/admin/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((result) => {
          let { data } = result.data;
          setValues(data);
          setLoading(false);
        });
  }, [id, isNew]);

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>{isNew ? "New" : "Update"} User</h1>
            </div>

            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {!loading && (
          <Row>
            <Colxx xxs="12" className="mb-4">
              <Card className="mb-4">
                <CardBody>
                  <Form>
                    <Row>
                      <Col md="8">
                        <FormGroup row>
                          <Label sm="3">username</Label>
                          <Colxx sm="9">
                            <Input
                              type="text"
                              name="username"
                              value={values.username}
                              onChange={handleChange}
                            />
                          </Colxx>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <Label>Password</Label>
                          <Input
                            type="text"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup md="4">
                          <Label>Role</Label>
                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            name="role"
                            value={{
                              label: values.role,
                              value: values.role,
                            }}
                            onChange={(e) =>
                              setValues({ ...values, role: e.value })
                            }
                            options={[
                              { label: "User", value: "User" },
                              { label: "Manager", value: "Manager" },
                              { label: "Admin", value: "Admin" },
                            ]}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        )}
        <div>
          <Button
            color="primary"
            className="top-right-button"
            onClick={() => navigate("/app/users")}
          >
            Cancel
          </Button>
          &nbsp;
          {!isNew && (
            <Button
              color="primary"
              className="top-right-button"
              onClick={handleDeleteUser}
            >
              Delete
            </Button>
          )}
          &nbsp;
          <Button
            color="primary"
            className="top-right-button"
            onClick={handleSubmit}
          >
            {isNew ? "Add" : "Update"}
          </Button>
        </div>
        &nbsp;
        <div>{errorMessage}</div>
      </div>
    </AppLayout>
  );
};

export default AddUser;
