import React from "react";
import {
  Card,
  FormGroup,
  Label,
  Input,
  Col,
  Button,
  CardBody,
  Row,
} from "reactstrap";
import { Colxx } from "components/common/CustomBootstrap";

const GapFilling = (props) => {
  const { gapFillingQuestions, setGapFillingQuestions } = props;

  const handleGapFillingDetails = (e, index) => {
    let newQuestions = [...gapFillingQuestions];
    newQuestions[index][e.target.name] = e.target.value;
    setGapFillingQuestions(newQuestions);
  };
  const handleGapFillingAddNextOption = (e, index) => {
    let newQuestions = [...gapFillingQuestions];
    newQuestions[index].answers.push([""]);
    setGapFillingQuestions(newQuestions);
  };

  const handleRemoveOption = (e, index, inputIndex) => {
    let newQuestions = [...gapFillingQuestions];
    newQuestions[index].answers.splice(inputIndex, 1);
    setGapFillingQuestions(newQuestions);
  };

  const handleGapFillingAddOption = (e, index, answerIndex) => {
    let newQuestions = [...gapFillingQuestions];
    newQuestions[index]?.answers[answerIndex]?.push("");
    setGapFillingQuestions(newQuestions);
  };

  const handleGapFillingChangeOption = (e, index, innerIndex, answerIndex) => {
    let newQuestions = [...gapFillingQuestions];
    newQuestions[index].answers[innerIndex][answerIndex] = e.target.value;
    setGapFillingQuestions(newQuestions);
  };

  const handleGapFillingAddQuestion = () => {
    setGapFillingQuestions([
      ...gapFillingQuestions,
      { prompt: "", answers: [[""]] },
    ]);
  };

  const handleGapFillingRemoveQuestion = (e, index) => {
    let newQuestions = [...gapFillingQuestions];
    newQuestions.splice(index, 1);
    setGapFillingQuestions(newQuestions);
  };

  return (
    <>
      <Colxx xxs="12" className="mb-4">
        {gapFillingQuestions.map((question, index) => (
          <Card className="p-4 m-4" key={index}>
            <div className="position-absolute card-top-buttons">
              <span
                className="btn btn-header-light icon-button"
                style={{ cursor: "pointer" }}
                onClick={(e) => handleGapFillingRemoveQuestion(e, index)}
              >
                <i className="simple-icon-trash" />
              </span>
            </div>
            <CardBody>
              <FormGroup row>
                <Label sm="3">Question {index + 1}</Label>
                <Colxx sm="9" className="text-left">
                  <Input
                    type="textarea"
                    name="prompt"
                    value={question.prompt}
                    onChange={(e) => handleGapFillingDetails(e, index)}
                  />
                  {question.prompt.charAt(0) === "_" && (
                    <span className="danger">
                      You cannot start with underline
                    </span>
                  )}
                  {question.prompt.charAt(question.prompt.length - 1) ===
                    "_" && (
                    <span className="text-danger">
                      You cannot end with underline
                    </span>
                  )}
                </Colxx>
              </FormGroup>
              <FormGroup row>
                <Label sm="3">
                  Answers
                  <span
                    className="btn btn-header-light icon-button ml-1"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => handleGapFillingAddNextOption(e, index)}
                  >
                    <i className="simple-icon-plus" />
                  </span>
                </Label>
                <Colxx sm="9" row>
                  {question.answers.map((options, innerIndex) => (
                    <Row key={innerIndex} className="text-left flex">
                      <span
                        className="btn btn-header-light icon-button mt-2"
                        style={{ cursor: "pointer" }}
                        onClick={(e) =>
                          handleRemoveOption(e, index, innerIndex)
                        }
                      >
                        <i className="simple-icon-minus" />
                      </span>
                      <span className="mt-3">{innerIndex + 1}</span>
                      {options.map((key, answerIndex) => (
                        <Col md="4" className="my-2" key={answerIndex}>
                          <Input
                            type="text"
                            value={key}
                            onChange={(e) =>
                              handleGapFillingChangeOption(
                                e,
                                index,
                                innerIndex,
                                answerIndex
                              )
                            }
                          />
                        </Col>
                      ))}
                      <Col md="1">
                        <Button
                          onClick={(e) =>
                            handleGapFillingAddOption(e, index, innerIndex)
                          }
                        >
                          +
                        </Button>
                      </Col>
                    </Row>
                  ))}
                </Colxx>
              </FormGroup>
            </CardBody>
          </Card>
        ))}

        <Button className="mt-3" onClick={handleGapFillingAddQuestion}>
          Add Question
        </Button>
      </Colxx>
    </>
  );
};

export default GapFilling;
