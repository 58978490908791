import React, { useState } from "react";
import { Button, Card, Col, FormGroup, Label, Input } from "reactstrap";
import { Colxx } from "components/common/CustomBootstrap";
import Resizer from "react-image-file-resizer";
import axios from "axiosClient";

import { CKEditor } from "ckeditor4-react";
import parse from "html-react-parser";

const ContentTask = ({
  showEditor,
  setContent,
  content,
  image,
  setImage,
  token,
}) => {
  const editorConfig = {
    toolbar: [
      ["Undo", "Redo"],
      ["Format", "Font", "FontSize"],
      ["TextColor", "BGColor"],
      ["JustifyLeft", "JustifyCenter", "JustifyRight", "JustifyBlock"],
      [
        "Bold",
        "Italic",
        "Underline",
        "Strike",
        "RemoveFormat",
        "CopyFormatting",
      ],
      ["Link", "Unlink"],
      [
        "NumberedList",
        "BulletedList",
        "-",
        "Outdent",
        "Indent",
        "-",
        "Blockquote",
      ],
      ["Image", "Table"],
      ["Scayt"],
    ],
    extraPlugins: "justify,format,font,colorbutton",
  };

  const [preview, setPreview] = useState("");
  const [imageLoading, setImageLoading] = useState(false);
  //image
  const handleImage = (e) => {
    let file = e.target.files[0];
    let correctImageTypes = ["JPEG", "PNG", "WEBP"];
    let fileType = file.type.split("/")[1].toUpperCase();

    if (!correctImageTypes.includes(fileType)) {
      alert("Image should be either JPEG, PNG or WEBP");
    } else {
      setPreview(window.URL.createObjectURL(file));
      setImageLoading(true);

      //resize
      Resizer.imageFileResizer(
        file,
        720,
        500,
        fileType,
        100,
        0,
        async (uri) => {
          try {
            let { data } = await axios.post(
              "/api/upload-image",
              {
                image: uri,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            //set image in te state
            setImage(data);
            setImageLoading(false);
          } catch (err) {
            console.log(err);
            setImageLoading(false);
          }
        }
      );
    }
  };

  const handleImageRemove = async () => {
    try {
      setImageLoading(true);
      await axios.post(
        "/api/remove-image",
        { image },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setImage({});
      setPreview("");
      setImageLoading(false);
    } catch (err) {
      console.log(err);
      setImageLoading(false);
    }
  };

  return (
    <>
      <Colxx md="8" className="mb-4">
        <Card className="p-4">
          <h2>Editor</h2>
          {showEditor && (
            <CKEditor
              onChange={(event) => setContent(event.editor.getData())}
              config={editorConfig}
              initData={content}
            />
          )}

          <br />
          <h2>Content</h2>
          {parse(content)}
        </Card>
      </Colxx>
      <Colxx md="4" className="mb-4">
        <FormGroup row>
          <Label sm="3">Image</Label>
          <Colxx sm="9">
            <Input
              type="file"
              name="image"
              onChange={handleImage}
              accept="image/*"
              disabled={imageLoading}
            />
          </Colxx>
        </FormGroup>
        {preview && (
          <Col>
            <img alt={"logo"} src={preview} className="card-img-top" />
            {!imageLoading && (
              <p>
                {image.Location} |{" "}
                <button
                  onClick={navigator.clipboard.writeText(`${image.Location}`)}
                >
                  Copy
                </button>
              </p>
            )}
          </Col>
        )}
      </Colxx>
    </>
  );
};

export default ContentTask;
