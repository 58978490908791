import React, { useEffect, useState } from "react";
import { Button, Row, Card, Col, FormGroup, Label, Input } from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axiosClient";
import { useToken } from "auth/useToken";
import { useUser } from "auth/useUser";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const CoursePage = () => {
  const [data, setData] = useState([]);
  const [newModule, setNewModule] = useState("");
  const [modules, setModules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [draggable, setDraggable] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [token, setToken] = useToken();
  const user = useUser();
  const location = useLocation();
  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`/api/course-modules/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        setData(result.data.data);
        setModules(result.data.data.modules);
        setLoading(false);
      });
  }, []);

  const navigate = useNavigate();

  const handleAddModule = () => {
    setButtonLoading(true);
    axios
      .post(
        `/api/courses/modules`,
        { courseId: id, title: newModule, author: user.username },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        setModules([...modules, result.data.data]);
      });
    setButtonLoading(false);
    setNewModule("");
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newModules = [...modules];
    const [reorderedItem] = newModules.splice(result.source.index, 1);
    newModules.splice(result.destination.index, 0, reorderedItem);

    setModules(newModules);
  };

  const handleOrderSubmit = () => {
    setLoading(true);
    axios
      .post(
        `/api/courses/${id}`,
        { modules },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        setData(result.data.data);
        setModules(result.data.data.modules);
        setLoading(false);
        setDraggable(false);
        navigate(0);
      });
  };

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>Course - {data.title} </h1>
              <i
                className="ml-2 clickable simple-icon-pencil"
                onClick={() => navigate(`/app/course/${id}`)}
              />
              <div className="text-zero top-right-button-container"></div>
            </div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {!draggable && (
          <Row>
            <div className="loading" hidden={!loading} />
            {!loading && modules.length === 0 ? (
              <p>List is empty</p>
            ) : (
              modules.map((module, index) => (
                <Colxx xxs="12" key={module._id} className="mb-3">
                  <Card className="d-flex flex-row">
                    <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                      <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                        <NavLink
                          to={`/app/module/${module._id}`}
                          className="w-40 w-sm-100"
                        >
                          <p className="list-item-heading mb-1 truncate">
                            Module {index + 1}: {module.title}
                          </p>
                        </NavLink>
                        <p className="mb-1 text-muted text-small w-15 w-sm-100">
                          {module.author}
                        </p>
                      </div>
                    </div>
                  </Card>
                </Colxx>
              ))
            )}
          </Row>
        )}
        <Row>
          {draggable && (
            <Colxx xxs="12">
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="tasks">
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      xxs="12"
                    >
                      <Colxx xxs="12">
                        {modules.map((module, index) => (
                          <Draggable
                            key={module._id}
                            draggableId={module._id}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                xxs="12"
                              >
                                <Colxx className="mb-2">
                                  <Card className="d-flex flex-row">
                                    <div className=" d-flex flex-grow-1 min-width-zero">
                                      <div className="m-3 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                                        <p className="mb-0 ml-4">
                                          {module.title}
                                        </p>
                                      </div>
                                    </div>
                                  </Card>
                                </Colxx>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </Colxx>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Colxx>
          )}
        </Row>
        {modules.length > 1 && (
          <Row className="mb-2">
            <Col md="9"></Col>
            <Col md="3">
              {!draggable && (
                <Button onClick={() => setDraggable(!draggable)}>
                  Reorder items
                </Button>
              )}
              {draggable && (
                <>
                  <Button
                    onClick={() => handleOrderSubmit()}
                    disabled={buttonLoading}
                    className="ml-2"
                  >
                    Update
                  </Button>
                  <Button
                    onClick={() => setDraggable(!draggable)}
                    className="ml-2"
                  >
                    Cancel
                  </Button>
                </>
              )}
            </Col>
          </Row>
        )}
        <Separator className="mb-5" />
        <Row>
          <Col md="8">
            <FormGroup row>
              <Label sm="3">Module Name</Label>
              <Colxx sm="9">
                <Input
                  type="text"
                  name="newModule"
                  value={newModule}
                  onChange={(e) => setNewModule(e.target.value)}
                />
              </Colxx>
            </FormGroup>
          </Col>
          <Col md="4">
            <Button onClick={handleAddModule} disabled={buttonLoading}>
              Add Module
            </Button>
            <Button
              onClick={() => navigate(-1)}
              disabled={buttonLoading}
              className="ml-2"
            >
              Back
            </Button>
          </Col>
        </Row>
      </div>
    </AppLayout>
  );
};

export default CoursePage;
