import React from "react";
import {
  Card,
  FormGroup,
  Label,
  Input,
  Col,
  Button,
  CardBody,
  Row,
} from "reactstrap";
import { Colxx } from "components/common/CustomBootstrap";

const UnderlineTask = (props) => {
  const { questions, setQuestions } = props;

  const letters = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];

  const handleDetails = (e, index) => {
    let newQuestions = [...questions];
    newQuestions[index][e.target.name] = e.target.value;
    setQuestions(newQuestions);
  };
  const handleAddNextOption = (e, index) => {
    let newQuestions = [...questions];
    newQuestions[index].inputs.push({ options: { A: "" }, answer: "" });
    setQuestions(newQuestions);
  };

  const handleRemoveOption = (e, index, inputIndex) => {
    let newQuestions = [...questions];
    newQuestions[index].inputs.splice(inputIndex, 1);
    setQuestions(newQuestions);
  };

  const handleChangeOption = (e, index, innerIndex, answerIndex) => {
    let newQuestions = [...questions];
    let letter = letters[answerIndex];
    newQuestions[index].inputs[innerIndex].options[letter] = e.target.value;
    setQuestions(newQuestions);
  };

  const handleAddOption = (e, index, inputIndex) => {
    let newQuestions = [...questions];
    let newLetter =
      letters[
        Object.keys(newQuestions[index].inputs[inputIndex].options).length
      ];
    newQuestions[index].inputs[inputIndex].options[newLetter] = "";
    setQuestions(newQuestions);
  };

  const handleInputAnswer = (e, index, answerIndex) => {
    let newQuestions = [...questions];
    newQuestions[index].inputs[answerIndex].answer =
      e.target.value.toUpperCase();
    setQuestions(newQuestions);
  };
  const handleAddQuestion = () => {
    setQuestions([
      ...questions,
      { prompt: "", inputs: [{ options: { A: "" }, answer: "" }] },
    ]);
  };

  const handleRemoveQuestion = (e, index) => {
    let newQuestions = [...questions];
    newQuestions.splice(index, 1);
    setQuestions(newQuestions);
  };

  return (
    <>
      <Colxx xxs="12" className="mb-4">
        {questions.map((question, index) => (
          <Card className="p-4 m-4" key={index}>
            <div className="position-absolute card-top-buttons">
              <span
                className="btn btn-header-light icon-button"
                style={{ cursor: "pointer" }}
                onClick={(e) => handleRemoveQuestion(e, index)}
              >
                <i className="simple-icon-trash" />
              </span>
            </div>
            <CardBody>
              <FormGroup row>
                <Label sm="3">Question {index + 1}</Label>
                <Colxx sm="9" className="text-left">
                  <Input
                    type="textarea"
                    name="prompt"
                    value={question.prompt}
                    onChange={(e) => handleDetails(e, index)}
                  />
                  {question.prompt.charAt(0) === "_" && (
                    <span className="danger">
                      You cannot start with underline
                    </span>
                  )}
                  {question.prompt.charAt(question.prompt.length - 1) ===
                    "_" && (
                    <span className="text-danger">
                      You cannot end with underline
                    </span>
                  )}
                </Colxx>
              </FormGroup>
              <FormGroup row>
                <Label sm="3">
                  Answers
                  <span
                    className="btn btn-header-light icon-button ml-1"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => handleAddNextOption(e, index)}
                  >
                    <i className="simple-icon-plus" />
                  </span>
                </Label>
                <Colxx sm="9" row>
                  {question.inputs.map((input, inputIndex) => (
                    <div key={inputIndex}>
                      <Row className="text-left flex">
                        <span
                          className="btn btn-header-light icon-button mt-2"
                          style={{ cursor: "pointer" }}
                          onClick={(e) =>
                            handleRemoveOption(e, index, inputIndex)
                          }
                        >
                          <i className="simple-icon-minus" />
                        </span>
                        <span className="mt-3">{inputIndex + 1}</span>
                        {Object.keys(input.options).map(
                          (optionKey, optionIndex) => (
                            <Col md="4" className="my-2" key={optionIndex}>
                              <FormGroup row>
                                <Label sm="2">{optionKey}</Label>
                                <Colxx sm="10">
                                  <Input
                                    type="text"
                                    value={input.options[optionKey]}
                                    onChange={(e) =>
                                      handleChangeOption(
                                        e,
                                        index,
                                        inputIndex,
                                        optionIndex
                                      )
                                    }
                                  />
                                </Colxx>
                              </FormGroup>
                            </Col>
                          )
                        )}
                        <Col md="1">
                          <Button
                            onClick={(e) =>
                              handleAddOption(e, index, inputIndex)
                            }
                          >
                            +
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <FormGroup row>
                            <Label sm="3">Answer</Label>
                            <Colxx sm="9">
                              <Input
                                type="text"
                                name="answer"
                                value={input.answer}
                                onChange={(e) =>
                                  handleInputAnswer(e, index, inputIndex)
                                }
                              />
                            </Colxx>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </Colxx>
              </FormGroup>
            </CardBody>
          </Card>
        ))}

        <Button className="mt-3" onClick={handleAddQuestion}>
          Add Question
        </Button>
      </Colxx>
    </>
  );
};

export default UnderlineTask;
