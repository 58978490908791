import React from "react";
import {
  Card,
  FormGroup,
  Label,
  Input,
  Col,
  Button,
  CardBody,
  Row,
} from "reactstrap";

import { Colxx } from "components/common/CustomBootstrap";

const Matching = (props) => {
  const { questions, setQuestions } = props;

  const handleDetails = (e, index) => {
    let newQuestions = [...questions];
    newQuestions[index][e.target.name] = e.target.value;
    setQuestions(newQuestions);
  };

  const handleChangeOption = (e, index) => {
    let newQuestions = [...questions];
    newQuestions[index].answer = e.target.value;
    setQuestions(newQuestions);
  };

  const handleAddQuestion = () => {
    setQuestions([...questions, { prompt: "", answer: "" }]);
  };

  const handleRemoveQuestion = (e, index) => {
    let newQuestions = [...questions];
    newQuestions.splice(index, 1);
    setQuestions(newQuestions);
  };

  return (
    <>
      <Colxx xxs="12" className="mb-4">
        {questions.map((question, index) => (
          <Card className="p-4 m-4" key={index}>
            <div className="position-absolute card-top-buttons">
              <span
                className="btn btn-header-light icon-button"
                style={{ cursor: "pointer" }}
                onClick={(e) => handleRemoveQuestion(e, index)}
              >
                <i className="simple-icon-trash" />
              </span>
            </div>
            <CardBody>
              <FormGroup row>
                <Label sm="3">Question {index + 1}</Label>
                <Colxx sm="9" className="text-left">
                  <Input
                    type="textarea"
                    name="prompt"
                    value={question.prompt}
                    onChange={(e) => handleDetails(e, index)}
                  />
                </Colxx>
              </FormGroup>
              <FormGroup row>
                <Label sm="3">Answer</Label>
                <Colxx sm="9" row>
                  <Row className="text-left flex">
                    <Col md="6">
                      <Input
                        type="text"
                        value={questions[index].answer}
                        onChange={(e) => handleChangeOption(e, index)}
                      />
                    </Col>
                  </Row>
                </Colxx>
              </FormGroup>
            </CardBody>
          </Card>
        ))}

        <Button className="mt-3" onClick={handleAddQuestion}>
          Add Question
        </Button>
      </Colxx>
    </>
  );
};

export default Matching;
