import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Card,
  Col,
  FormGroup,
  Label,
  Input,
  CardBody,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axiosClient";
import { useToken } from "auth/useToken";
import { useUser } from "auth/useUser";
import Select from "react-select";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";

import data from "constants/menu";
import ContentTask from "components/taskTypes/ContentTask";
import VideoTask from "components/taskTypes/VideoTask";
import QuizTask from "components/taskTypes/QuizTask";
import GapFilling from "components/taskTypes/GapFilling";
import Correction from "components/taskTypes/Correction";
import AudioTask from "components/taskTypes/AudioTask";
import UnderlineTask from "components/taskTypes/UnderlineTask";
import SentenceCompletion from "components/taskTypes/SentenceCompletion";
import ChooseFromBoxTask from "components/taskTypes/ChooseFromBoxTask";
import MatchingToPictures from "components/taskTypes/MatchingToPictures";
import Matching from "components/taskTypes/Matching";
import InnerTasks from "components/taskTypes/InnerTasks";
import { toast } from "react-toastify";
import ConfirmModal from "components/ConfirmModal";

const TaskPage = () => {
  const [data, setData] = useState([]);
  const [type, setType] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [available, setAvailable] = useState(true);
  const [videoUrl, setVideoUrl] = useState("");
  const [image, setImage] = useState({});
  const [file, setFile] = useState({});

  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [content, setContent] = useState("");
  const [token, setToken] = useToken();
  const user = useUser();
  const location = useLocation();
  const { id } = useParams();
  const [deleteModal, setDeleteModal] = useState(false);
  const [duplicateModal, setDuplicateModal] = useState(false);

  useEffect(() => {
    axios
      .get(`/api/courses/modules/task/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        const { data } = result.data;
        setData(data);
        setType(data.type);
        setTitle(data.title);
        setDescription(data.description);
        setAvailable(data.available);
        // eslint-disable-next-line default-case
        switch (data.type) {
          case "Content":
            setContent(data.content);
            setShowEditor(true);
            break;
          case "Video":
            setVideoUrl(data.videoUrl);
            break;
          case "Video+Content":
            setContent(data.content ? data.content : "");
            setVideoUrl(data.videoUrl);
            setShowEditor(true);
            break;
          case "Quiz":
            setQuestions(data.questions);
            break;
          case "Gap Filling":
            if (data.questions) {
              setGapFillingQuestions(data.questions);
            }
            break;
          case "Correction":
            if (data.questions) {
              setCorrectionQuestions(data.questions);
            }
            break;
          case "Sentence Completion":
            if (data.questions) {
              setSentenceCompletionQuestions(data.questions);
            }
            break;
          case "Underline":
            if (data.questions) {
              setUnderlineQuestions(data.questions);
            }
            break;
          case "Choose From Box":
            if (data.questions) {
              setChooseFromBoxQuestions(data.questions);
            }
            break;
          case "Matching to Picture":
            if (data.questions) {
              setMatchingToPicturesQuestions(data.questions);
            }
            break;
          case "Matching":
            if (data.questions) {
              setMatchingQuestions(data.questions);
            }
            break;
          case "Content+Audio":
            setContent(data.content ? data.content : "");
            setFile(data.file);
            setShowEditor(true);
            break;
          case "Listening+Tasks":
            setContent(data.content ? data.content : "");
            setInnerTasks(data.innerTasks);
            setFile(data.file);
            setShowEditor(true);
            break;
          case "Reading+Tasks":
            setContent(data.content);
            setInnerTasks(data.innerTasks);
            setShowEditor(true);
            break;
        }
        setLoading(false);
      });
  }, []);

  const navigate = useNavigate();

  //Quiz Type
  const [questions, setQuestions] = useState([
    { prompt: "", options: { A: "" }, answer: "" },
  ]);

  //Gap Filling Type
  const [gapFillingQuestions, setGapFillingQuestions] = useState([
    { prompt: "", answers: [[""]] },
  ]);

  //Sentence Completion Type
  const [sentenceCompletionQuestions, setSentenceCompletionQuestions] =
    useState([{ prompt: "", answers: [1] }]);

  //Unedrline Type
  const [underlineQuestions, setUnderlineQuestions] = useState([
    { prompt: "", inputs: [{ options: { A: "" }, answer: "" }] },
  ]);

  //Correction Type
  const [correctionQuestions, setCorrectionQuestions] = useState([
    { prompt: "", answers: [[""]] },
  ]);

  //Choose From Box
  const [chooseFromBoxQuestions, setChooseFromBoxQuestions] = useState([
    { prompt: "", answers: [""] },
  ]);

  //Matching To Picture
  const [matchingToPictureQuestions, setMatchingToPicturesQuestions] = useState(
    [{ prompt: "", answer: "" }]
  );

  //Matching
  const [matchingQuestions, setMatchingQuestions] = useState([
    { prompt: "", answer: "" },
  ]);

  //Inner Tasks
  const [innerTasks, setInnerTasks] = useState([]);

  const handleDelete = () => {
    setButtonLoading(true);
    axios
      .delete(`/api/courses/modules/task/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        setButtonLoading(false);
        if (result.status === 200) {
          navigate(-1);
        }
      });
  };

  const handleDuplicate = (id) => {
    setButtonLoading(true);
    axios
      .post(
        `/api/courses/modules/task`,
        {
          available: true,
          author: data.author,
          content: data.content,
          courseId: data.courseId,
          description: data.description,
          moduleId: data.moduleId,
          questions: data.questions,
          title: data.title,
          type: data.type,
          videoUrl: data.videoUrl,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        setButtonLoading(false);
        if (result.status === 200) {
          navigate(-1);
        }
      });
  };

  const handleUpdate = () => {
    let data = {};
    let common = { title, type, description, available };
    // eslint-disable-next-line default-case
    switch (type) {
      case "Content":
        data = { ...common, content };
        break;
      case "Video":
        data = { ...common, videoUrl };
        break;
      case "Video+Content":
        data = { ...common, videoUrl, content };
        break;
      case "Quiz":
        data = { ...common, questions };
        break;
      case "Gap Filling":
        data = { ...common, questions: gapFillingQuestions };
        break;
      case "Correction":
        data = { ...common, questions: correctionQuestions };
        break;
      case "Underline":
        data = { ...common, questions: underlineQuestions };
        break;
      case "Sentence Completion":
        data = { ...common, questions: sentenceCompletionQuestions };
        break;
      case "Choose From Box":
        data = { ...common, questions: chooseFromBoxQuestions };
        break;
      case "Matching to Picture":
        data = { ...common, questions: matchingToPictureQuestions };
        break;
      case "Matching":
        data = { ...common, questions: matchingQuestions };
        break;
      case "Content+Audio":
        data = { ...common, file, content };
        break;
      case "Listening+Tasks":
        data = { ...common, file, content, innerTasks };
        break;
      case "Reading+Tasks":
        data = { ...common, content, innerTasks };
        break;
    }
    setButtonLoading(true);
    // console.log(data);
    axios
      .post(`/api/courses/modules/task/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        // console.log(result);
        toast.success("Successfully updated");
        navigate(-1);
      });
    setButtonLoading(false);
  };

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>Task - {data.title}</h1>
              <Row>
                <Col md="4" className="mx-auto">
                  <FormGroup row>
                    <Label sm="3">Name</Label>
                    <Colxx sm="9">
                      <Input
                        type="text"
                        name="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </Colxx>
                  </FormGroup>
                </Col>
                <Col md="7" sm="11" className="mx-auto">
                  <FormGroup row>
                    <Label sm="2">Description</Label>
                    <Colxx sm="10">
                      <Input
                        type="text"
                        name="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </Colxx>
                  </FormGroup>
                </Col>
                <Col md="1" sm="1">
                  <Switch
                    className="custom-switch custom-switch-primary-inverse"
                    checked={available}
                    onChange={(e) => setAvailable(!available)}
                  />
                </Col>
              </Row>
              <Button className="mr-2" onClick={() => setDeleteModal(true)}>
                Delete
              </Button>
              <Button className="mr-2" onClick={() => setDuplicateModal(true)}>
                Duplicate
              </Button>
            </div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <div className="loading" hidden={!loading} />
          {!loading && (
            <>
              <Col md="8" className="mx-auto">
                <FormGroup row>
                  <Label sm="3">Task Type</Label>
                  <Colxx sm="9">
                    <Select
                      className="react-select"
                      classNamePrefix="react-select"
                      name="role"
                      value={{
                        label: type,
                        value: type,
                      }}
                      onChange={(e) => {
                        setType(e.value);
                        if (
                          e.value === "Content" ||
                          e.value === "Video+Content" ||
                          e.value === "Content+Audio" ||
                          e.value === "Listening+Tasks" ||
                          e.value === "Reading+Tasks"
                        ) {
                          setShowEditor(true);
                        }
                      }}
                      options={[
                        { label: "Video", value: "Video" },
                        { label: "Content", value: "Content" },
                        { label: "Video+Content", value: "Video+Content" },
                        { label: "Quiz", value: "Quiz" },
                        { label: "Gap Filling", value: "Gap Filling" },
                        { label: "Underline", value: "Underline" },
                        {
                          label: "Sentence Completion",
                          value: "Sentence Completion",
                        },
                        { label: "Correction", value: "Correction" },
                        {
                          label: "Choose From Box",
                          value: "Choose From Box",
                        },
                        {
                          label: "Matching to Picture",
                          value: "Matching to Picture",
                        },
                        { label: "Matching", value: "Matching" },
                        { label: "Content+Audio", value: "Content+Audio" },
                        { label: "Listening+Tasks", value: "Listening+Tasks" },
                        { label: "Reading+Tasks", value: "Reading+Tasks" },
                      ]}
                    />
                  </Colxx>
                </FormGroup>
              </Col>
              {type === "Content" && (
                <ContentTask
                  showEditor={showEditor}
                  setContent={setContent}
                  content={content}
                  image={image}
                  setImage={setImage}
                  token={token}
                />
              )}
              {type === "Video" && (
                <VideoTask videoUrl={videoUrl} setVideoUrl={setVideoUrl} />
              )}
              {type === "Video+Content" && (
                <>
                  <VideoTask videoUrl={videoUrl} setVideoUrl={setVideoUrl} />
                  <ContentTask
                    showEditor={showEditor}
                    setContent={setContent}
                    content={content}
                    image={image}
                    setImage={setImage}
                    token={token}
                  />
                </>
              )}
              {type === "Quiz" && (
                <QuizTask questions={questions} setQuestions={setQuestions} />
              )}

              {type === "Gap Filling" && (
                <GapFilling
                  gapFillingQuestions={gapFillingQuestions}
                  setGapFillingQuestions={setGapFillingQuestions}
                />
              )}
              {type === "Underline" && (
                <UnderlineTask
                  questions={underlineQuestions}
                  setQuestions={setUnderlineQuestions}
                />
              )}

              {type === "Sentence Completion" && (
                <SentenceCompletion
                  questions={sentenceCompletionQuestions}
                  setQuestions={setSentenceCompletionQuestions}
                />
              )}
              {type === "Correction" && (
                <Correction
                  questions={correctionQuestions}
                  setQuestions={setCorrectionQuestions}
                />
              )}
              {type === "Choose From Box" && (
                <ChooseFromBoxTask
                  questions={chooseFromBoxQuestions}
                  setQuestions={setChooseFromBoxQuestions}
                />
              )}
              {type === "Matching to Picture" && (
                <MatchingToPictures
                  questions={matchingToPictureQuestions}
                  setQuestions={setMatchingToPicturesQuestions}
                  image={image}
                  setImage={setImage}
                  token={token}
                />
              )}
              {type === "Matching" && (
                <Matching
                  questions={matchingQuestions}
                  setQuestions={setMatchingQuestions}
                />
              )}
              {type === "Content+Audio" && (
                <>
                  <ContentTask
                    showEditor={showEditor}
                    setContent={setContent}
                    content={content}
                    image={image}
                    setImage={setImage}
                    token={token}
                  />
                  <AudioTask file={file} setFile={setFile} token={token} />
                </>
              )}
              {type === "Listening+Tasks" && (
                <>
                  <ContentTask
                    showEditor={showEditor}
                    setContent={setContent}
                    content={content}
                    image={image}
                    setImage={setImage}
                    token={token}
                  />
                  <AudioTask file={file} setFile={setFile} token={token} />
                  <InnerTasks tasks={innerTasks} setTasks={setInnerTasks} />
                </>
              )}
              {type === "Reading+Tasks" && (
                <>
                  <ContentTask
                    showEditor={showEditor}
                    setContent={setContent}
                    content={content}
                    image={image}
                    setImage={setImage}
                    token={token}
                  />
                  <InnerTasks tasks={innerTasks} setTasks={setInnerTasks} />
                </>
              )}
            </>
          )}
        </Row>
        <Separator />
        <Row className="mt-3">
          <Col md="8"></Col>
          <Col md="4">
            <Button
              onClick={(e) => navigate(-1)}
              disabled={buttonLoading}
              className="mr-2"
            >
              Cancel
            </Button>
            <Button onClick={handleUpdate} disabled={buttonLoading}>
              Update
            </Button>
          </Col>
        </Row>

        <ConfirmModal
          modal={deleteModal}
          setModal={setDeleteModal}
          action={handleDelete}
          title={`Are you sure you want to delete?`}
        />
        <ConfirmModal
          modal={duplicateModal}
          setModal={setDuplicateModal}
          action={handleDuplicate}
          title={`Are you sure you want to duplicate?`}
        />
      </div>
    </AppLayout>
  );
};

export default TaskPage;
