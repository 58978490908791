import React, { useState } from "react";
import { Card, FormGroup, Label, Input, Row, Button, Col } from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axiosClient";
import { useToken } from "auth/useToken";
import { useUser } from "auth/useUser";

const InnerTasks = (props) => {
  const { tasks, setTasks } = props;

  const [newTask, setNewTask] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [token, setToken] = useToken();
  const user = useUser();

  const navigate = useNavigate();

  const handleAddTask = () => {
    setButtonLoading(true);
    axios
      .post(
        `/api/courses/modules/task`,
        { title: newTask, author: user.username },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        setTasks([...tasks, result.data.data]);
      });
    setButtonLoading(false);
    setNewTask("");
  };

  const handleDelete = (id) => {
    setButtonLoading(true);
    axios
      .delete(`/api/courses/modules/task/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        setButtonLoading(false);
        if (result.status === 200) {
          navigate(0);
        }
      });
  };

  return (
    <>
      <Col xs="12">
        <Row>
          {tasks.length === 0 ? (
            <p>List is empty</p>
          ) : (
            tasks.map((task) => (
              <Colxx xxs="12" key={task._id} className="mb-3">
                <Card className="d-flex flex-row">
                  <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                    <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                      <NavLink
                        to={`/app/task/inner/${task._id}`}
                        target="_blank"
                        className="w-40 w-sm-100"
                      >
                        <p className="list-item-heading mb-1 truncate">
                          {task.title}
                        </p>
                      </NavLink>
                      <p className="mb-1 text-muted text-small w-15 w-sm-100">
                        {task.author}
                      </p>
                      <div className="mb-1 text-muted text-small w-15 w-sm-100">
                        <Button onClick={(e) => handleDelete(task._id)}>
                          <i className="simple-icon-trash" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Card>
              </Colxx>
            ))
          )}
        </Row>
        <Separator className="mb-5" />
        <Row>
          <Col md="8">
            <FormGroup row>
              <Label sm="3">Task Name</Label>
              <Colxx sm="9">
                <Input
                  type="text"
                  name="newTask"
                  value={newTask}
                  onChange={(e) => setNewTask(e.target.value)}
                />
              </Colxx>
            </FormGroup>
          </Col>
          <Col md="4">
            <Button onClick={handleAddTask} disabled={buttonLoading}>
              Add Task
            </Button>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default InnerTasks;
