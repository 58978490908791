import React, { useEffect, useState } from "react";
import axios from "axiosClient";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { useToken } from "auth/useToken";
import Resizer from "react-image-file-resizer";
import { toast } from "react-toastify";
import Select from "react-select";

const CourseDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [values, setValues] = useState("");
  const [preview, setPreview] = useState("");
  const [image, setImage] = useState({});

  const [errorMessage, setErrorMessage] = useState("");
  const [isNew, setIsNew] = useState(
    location.pathname.split("/").slice(-1)[0] === "add"
  );
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useToken();

  const { id } = useParams();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        `/api/courses/${id}`,
        { ...values, image, imageUrl: image?.Location },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        setValues(result.data.data);
        setLoading(false);
        navigate(-1);
      });
  };

  const handleDelete = () => {
    axios
      .delete(`/api/courses/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        if (result.data.success) {
          navigate(-1);
        }
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleImage = async (e) => {
    let file = e.target.files[0];
    let correctImageTypes = ["JPEG", "PNG", "WEBP"];
    let fileType = file.type.split("/")[1].toUpperCase();
    // console.log(fileType);

    if (!correctImageTypes.includes(fileType)) {
      toast.error("Only image files can be uploaded.");
    } else {
      setPreview(window.URL.createObjectURL(file));
      setLoading(true);
      //resize
      Resizer.imageFileResizer(
        file,
        720,
        500,
        fileType,
        100,
        0,
        async (uri) => {
          try {
            let { data } = await axios.post(
              "api/upload-image",
              {
                image: uri,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            //set image in te state
            setImage(data);
            setLoading(false);
          } catch (err) {
            console.log(err);
            setLoading(false);
          }
        }
      );
    }
  };

  const handleImageRemove = async () => {
    try {
      setLoading(true);
      await axios.post(
        "api/remove-image",
        { image },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setImage({});
      setPreview("");
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    !isNew && setLoading(true);
    !isNew &&
      axios
        .get(`/api/courses/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((result) => {
          let { data } = result;
          setValues(data.data);
          if (data.data.image) {
            setImage(data.data.image);
            setPreview(data.data.image.Location);
          }
          setLoading(false);
        });
  }, [id, isNew]);

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>{isNew ? "New" : "Update"} Course</h1>
            </div>

            <Separator className="mb-5" />
          </Colxx>
        </Row>

        <Row>
          <Colxx xxs="12" className="mb-4">
            <div className="loading" hidden={!loading} />
            <Card className="mb-4">
              <CardBody>
                <Form>
                  <Row>
                    <Col md="2">
                      <FormGroup>
                        <Label>Image</Label>
                        <Input
                          type="file"
                          name="image"
                          onChange={handleImage}
                          accept="image/*"
                          disabled={values.loading}
                        />
                      </FormGroup>
                      {preview && (
                        <>
                          <div class="position-absolute img-top-button">
                            <Button
                              outline
                              color="danger"
                              onClick={handleImageRemove}
                              className="icon-button btn btn-outline-red"
                            >
                              x
                            </Button>
                          </div>
                          <img
                            alt={"logo"}
                            src={preview}
                            className="card-img-top"
                          />
                        </>
                      )}
                    </Col>
                    <Col md="5">
                      <FormGroup>
                        <Label>Name</Label>
                        <Input
                          type="text"
                          name="name"
                          value={values.name}
                          onChange={(e) => handleChange(e)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label>Title</Label>
                        <Input
                          type="text"
                          name="title"
                          value={values.title}
                          onChange={(e) => handleChange(e)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label>Category</Label>
                        <Input
                          type="text"
                          name="category"
                          value={values.category}
                          onChange={(e) => handleChange(e)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label>Skill Level</Label>
                        <Input
                          type="text"
                          name="skillLevel"
                          value={values.skillLevel}
                          onChange={(e) => handleChange(e)}
                        />
                      </FormGroup>
                    </Col>

                    <Col md="5">
                      <div className="d-flex flex-row my-3 mt-4">
                        <h5>Is Live?</h5>
                        <span style={{ transform: "scale(0.8)" }}>
                          <Switch
                            className="mr-2 custom-switch custom-switch-primary-inverse"
                            checked={values.live}
                            onChange={(value) =>
                              setValues({ ...values, live: value })
                            }
                          />
                        </span>
                        <h5 className="ml-3">Is Available?</h5>
                        <span style={{ transform: "scale(0.8)" }}>
                          <Switch
                            className="mr-2 custom-switch custom-switch-primary-inverse"
                            checked={values.available}
                            onChange={(value) =>
                              setValues({ ...values, available: value })
                            }
                          />
                        </span>
                      </div>
                      <FormGroup>
                        <Label>Price (UZS)</Label>
                        <Input
                          type="number"
                          name="price"
                          value={values.price}
                          onChange={(e) => handleChange(e)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label>Videos Total Duration (hours)</Label>
                        <Input
                          type="number"
                          name="videoDuration"
                          value={values.videoDuration}
                          onChange={(e) => handleChange(e)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label>Total Hours</Label>
                        <Input
                          type="number"
                          name="hours"
                          value={values.hours}
                          onChange={(e) => handleChange(e)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label>Order No</Label>
                        <Input
                          type="number"
                          name="orderNo"
                          value={values.orderNo}
                          onChange={(e) => handleChange(e)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Separator className="mt-2 mb-4" />
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label>Languages Spoken (EN)</Label>
                        <Input
                          type="text"
                          name="languagesEn"
                          value={values.languagesEn}
                          onChange={(e) => handleChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Languages Spoken (UZ)</Label>
                        <Input
                          type="text"
                          name="languagesUz"
                          value={values.languagesUz}
                          onChange={(e) => handleChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup>
                        <Label>Overview (EN)</Label>
                        <Input
                          type="textarea"
                          name="overviewEn"
                          style={{ height: "200px" }}
                          value={values.overviewEn}
                          onChange={(e) => handleChange(e)}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label>Overview (UZ)</Label>
                        <Input
                          type="textarea"
                          name="overviewUz"
                          style={{ height: "200px" }}
                          value={values.overviewUz}
                          onChange={(e) => handleChange(e)}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label>Curriculum (EN)</Label>
                        <Input
                          type="textarea"
                          name="curriculumEn"
                          style={{ height: "200px" }}
                          value={values.curriculumEn}
                          onChange={(e) => handleChange(e)}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label>Curriculum (UZ)</Label>
                        <Input
                          type="textarea"
                          name="curriculumUz"
                          style={{ height: "200px" }}
                          value={values.curriculumUz}
                          onChange={(e) => handleChange(e)}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label>Videosamples (divided by comma)</Label>
                        <Input
                          type="text"
                          name="videoSamples"
                          value={values.videoSamples}
                          onChange={(e) => handleChange(e)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
        <div>
          <Button
            color="light"
            className="top-right-button "
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            className="top-right-button ml-2"
            onClick={handleSubmit}
          >
            {isNew ? "Add" : "Update"}
          </Button>
        </div>
        <div>{errorMessage}</div>
      </div>
    </AppLayout>
  );
};

export default CourseDetails;
