import React from "react";
import {
  Card,
  FormGroup,
  Label,
  Input,
  Col,
  Button,
  CardBody,
  Row,
} from "reactstrap";
import { Colxx } from "components/common/CustomBootstrap";

const ChooseFromBoxTask = (props) => {
  const { questions, setQuestions } = props;

  const handleDetails = (e, index) => {
    let newQuestions = [...questions];
    newQuestions[index][e.target.name] = e.target.value;
    setQuestions(newQuestions);
  };
  const handleAddNextOption = (e, index) => {
    let newQuestions = [...questions];
    newQuestions[index].answers.push([""]);
    setQuestions(newQuestions);
  };

  const handleRemoveOption = (e, index, inputIndex) => {
    let newQuestions = [...questions];
    newQuestions[index].answers.splice(inputIndex, 1);
    setQuestions(newQuestions);
  };

  const handleChangeOption = (e, index, innerIndex) => {
    let newQuestions = [...questions];
    newQuestions[index].answers[innerIndex] = e.target.value;
    setQuestions(newQuestions);
  };

  const handleAddQuestion = () => {
    setQuestions([...questions, { prompt: "", answers: [""] }]);
  };

  const handleRemoveQuestion = (e, index) => {
    let newQuestions = [...questions];
    newQuestions.splice(index, 1);
    setQuestions(newQuestions);
  };

  return (
    <>
      <Colxx xxs="12" className="mb-4">
        {questions.map((question, index) => (
          <Card className="p-4 m-4" key={index}>
            <div className="position-absolute card-top-buttons">
              <span
                className="btn btn-header-light icon-button"
                style={{ cursor: "pointer" }}
                onClick={(e) => handleRemoveQuestion(e, index)}
              >
                <i className="simple-icon-trash" />
              </span>
            </div>
            <CardBody>
              <FormGroup row>
                <Label sm="3">Question {index + 1}</Label>
                <Colxx sm="9" className="text-left">
                  <Input
                    type="textarea"
                    name="prompt"
                    value={question.prompt}
                    onChange={(e) => handleDetails(e, index)}
                  />
                  {question.prompt.charAt(0) === "_" && (
                    <span className="danger">
                      You cannot start with underline
                    </span>
                  )}
                  {question.prompt.charAt(question.prompt.length - 1) ===
                    "_" && (
                    <span className="text-danger">
                      You cannot end with underline
                    </span>
                  )}
                </Colxx>
              </FormGroup>
              <FormGroup row>
                <Label sm="3">
                  Answers
                  <span
                    className="btn btn-header-light icon-button ml-1"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => handleAddNextOption(e, index)}
                  >
                    <i className="simple-icon-plus" />
                  </span>
                </Label>
                <Colxx sm="9" row>
                  {question.answers.map((input, inputIndex) => (
                    <div key={inputIndex}>
                      <Row className="text-left flex">
                        <span
                          className="btn btn-header-light icon-button mt-2"
                          style={{ cursor: "pointer" }}
                          onClick={(e) =>
                            handleRemoveOption(e, index, inputIndex)
                          }
                        >
                          <i className="simple-icon-minus" />
                        </span>
                        <span className="mt-3">{inputIndex + 1}</span>
                        <Col md="6">
                          <Input
                            type="text"
                            value={input}
                            onChange={(e) =>
                              handleChangeOption(e, index, inputIndex)
                            }
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}
                </Colxx>
              </FormGroup>
            </CardBody>
          </Card>
        ))}

        <Button className="mt-3" onClick={handleAddQuestion}>
          Add Question
        </Button>
      </Colxx>
    </>
  );
};

export default ChooseFromBoxTask;
