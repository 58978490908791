import React from "react";
import { Card, FormGroup, Label, Input } from "reactstrap";
import { Colxx } from "components/common/CustomBootstrap";

const VideoTask = (props) => {
  const { videoUrl, setVideoUrl } = props;

  return (
    <>
      <Colxx xxs="12" className="mb-4">
        <Card className="p-4">
          <FormGroup row>
            <Label sm="3">Video URL</Label>
            <Colxx sm="9">
              <Input
                type="text"
                value={videoUrl}
                onChange={(e) => setVideoUrl(e.target.value)}
              />
            </Colxx>
          </FormGroup>
        </Card>
      </Colxx>
    </>
  );
};

export default VideoTask;
