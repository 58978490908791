import React, { useState } from "react";
import { Button, Card, Col, FormGroup, Label, Input } from "reactstrap";
import { Colxx } from "components/common/CustomBootstrap";
import ReactPlayer from "react-player";
import axios from "axiosClient";
import { toBase64 } from "utils/toBase64";

const AudioTask = (props) => {
  // const { handleAudio, audioLoading, audioPreview, handleAudioRemove, audio } =
  //   props;
  const { file, setFile, token } = props;

  //audio
  const handleAudio = async (e) => {
    let thiFile = e.target.files[0];
    try {
      const result = await toBase64(thiFile);
      let { data } = await axios.post(
        "/api/upload-file",
        {
          file: {
            extension:
              thiFile.name.split(".")[thiFile.name.split(".").length - 1],
            type: thiFile.type,
            base64Data: result,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      //set image in te state
      setFile(data);
    } catch (err) {
      console.log("error", err);
    }
  };

  const handleAudioRemove = async () => {
    try {
      // setImageLoading(true);
      await axios.post(
        "/api/remove-image",
        { file },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setFile({});
      // setImageLoading(false);
    } catch (err) {
      console.log(err);
      // setImageLoading(false);
    }
  };

  return (
    <>
      <Colxx md="8" className="mb-4 mx-auto">
        <Card className="p-4">
          <h2>Audio</h2>
          {file?.Location && (
            <>
              <p>{file.Location}</p>
              <ReactPlayer url={file.Location} controls={true} />
            </>
          )}
          <FormGroup row>
            <Label sm="3">Audio</Label>
            <Colxx sm="9">
              <Input
                type="file"
                name="image"
                onChange={handleAudio}
                accept="audio/*"
                // disabled={audioLoading}
              />
            </Colxx>
          </FormGroup>
        </Card>
      </Colxx>
    </>
  );
};

export default AudioTask;
