import React from "react";
import {
  Card,
  FormGroup,
  Label,
  Input,
  Col,
  Button,
  CardBody,
} from "reactstrap";
import { Colxx } from "components/common/CustomBootstrap";

const QuizTask = (props) => {
  const { questions, setQuestions } = props;

  const letters = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];

  const handleQuizDetails = (e, index) => {
    let newQuestions = [...questions];
    newQuestions[index][e.target.name] = e.target.value;
    setQuestions(newQuestions);
  };

  const handleAddOption = (e, index) => {
    let newQuestions = [...questions];
    let newLetter = letters[Object.keys(newQuestions[index].options).length];
    newQuestions[index].options[newLetter] = "";
    setQuestions(newQuestions);
  };

  const handleChangeOption = (e, index, innerIndex) => {
    let newQuestions = [...questions];
    let letter = letters[innerIndex];
    newQuestions[index].options[letter] = e.target.value;
    setQuestions(newQuestions);
  };

  const handleAddQuestion = () => {
    setQuestions([
      ...questions,
      { prompt: "", options: { A: "" }, answer: "" },
    ]);
  };

  const handleRemoveQuestion = (e, index) => {
    let newQuestions = [...questions];
    newQuestions.splice(index, 1);
    setQuestions(newQuestions);
  };

  return (
    <>
      <Colxx xxs="12" className="mb-4">
        {questions.map((question, index) => (
          <Card className="p-4 m-4" key={index}>
            <div className="position-absolute card-top-buttons">
              <span
                className="btn btn-header-light icon-button"
                style={{ cursor: "pointer" }}
                onClick={(e) => handleRemoveQuestion(e, index)}
              >
                <i className="simple-icon-trash" />
              </span>
            </div>
            <CardBody>
              <FormGroup row>
                <Label sm="3">Question {index + 1}</Label>
                <Colxx sm="9">
                  <Input
                    type="textarea"
                    name="prompt"
                    value={question.prompt}
                    onChange={(e) => handleQuizDetails(e, index)}
                  />
                </Colxx>
              </FormGroup>
              <FormGroup row>
                <Label sm="3">Options</Label>
                <Colxx sm="9">
                  {Object.keys(question.options).map((key, innerIndex) => (
                    <Col md="4" key={innerIndex}>
                      <FormGroup row>
                        <Label sm="3">{key}</Label>
                        <Colxx sm="9">
                          <Input
                            type="text"
                            value={question.options[key]}
                            onChange={(e) =>
                              handleChangeOption(e, index, innerIndex)
                            }
                          />
                        </Colxx>
                      </FormGroup>
                    </Col>
                  ))}

                  <Col md="4">
                    <Button onClick={(e) => handleAddOption(e, index)}>
                      +
                    </Button>
                  </Col>
                </Colxx>
              </FormGroup>
              <FormGroup row>
                <Label sm="3">Answer</Label>
                <Colxx sm="9">
                  <Input
                    type="text"
                    name="answer"
                    value={question.answer}
                    onChange={(e) => handleQuizDetails(e, index)}
                  />
                </Colxx>
              </FormGroup>
            </CardBody>
          </Card>
        ))}

        <Button className="mt-3" onClick={handleAddQuestion}>
          Add Question
        </Button>
      </Colxx>
    </>
  );
};

export default QuizTask;
