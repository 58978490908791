import React, { useState } from "react";
import {
  Card,
  FormGroup,
  Label,
  Input,
  Col,
  Button,
  CardBody,
  Row,
} from "reactstrap";
import Resizer from "react-image-file-resizer";
import axios from "axiosClient";

import { Colxx } from "components/common/CustomBootstrap";

const MatchingToPictures = (props) => {
  const { questions, setQuestions, image, setImage, token } = props;

  const [preview, setPreview] = useState("");
  const [imageLoading, setImageLoading] = useState(false);
  //image
  const handleImage = (e) => {
    let file = e.target.files[0];
    let correctImageTypes = ["JPEG", "PNG", "WEBP"];
    let fileType = file.type.split("/")[1].toUpperCase();

    if (!correctImageTypes.includes(fileType)) {
      alert("Image should be either JPEG, PNG or WEBP");
    } else {
      setPreview(window.URL.createObjectURL(file));
      setImageLoading(true);

      //resize
      Resizer.imageFileResizer(
        file,
        720,
        500,
        fileType,
        100,
        0,
        async (uri) => {
          try {
            let { data } = await axios.post(
              "/api/upload-image",
              {
                image: uri,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            //set image in te state
            setImage(data);
            setImageLoading(false);
          } catch (err) {
            console.log(err);
            setImageLoading(false);
          }
        }
      );
    }
  };

  const handleImageRemove = async () => {
    try {
      setImageLoading(true);
      await axios.post(
        "/api/remove-image",
        { image },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setImage({});
      setPreview("");
      setImageLoading(false);
    } catch (err) {
      console.log(err);
      setImageLoading(false);
    }
  };

  const handleDetails = (e, index) => {
    let newQuestions = [...questions];
    newQuestions[index][e.target.name] = e.target.value;
    setQuestions(newQuestions);
  };

  const handleChangeOption = (e, index) => {
    let newQuestions = [...questions];
    newQuestions[index].answer = e.target.value;
    setQuestions(newQuestions);
  };

  const handleAddQuestion = () => {
    setQuestions([...questions, { prompt: "", answer: "" }]);
  };

  const handleRemoveQuestion = (e, index) => {
    let newQuestions = [...questions];
    newQuestions.splice(index, 1);
    setQuestions(newQuestions);
  };

  return (
    <>
      <Colxx xxs="9" className="mb-4">
        {questions.map((question, index) => (
          <Card className="p-4 m-4" key={index}>
            <div className="position-absolute card-top-buttons">
              <span
                className="btn btn-header-light icon-button"
                style={{ cursor: "pointer" }}
                onClick={(e) => handleRemoveQuestion(e, index)}
              >
                <i className="simple-icon-trash" />
              </span>
            </div>
            <CardBody>
              <FormGroup row>
                <Label sm="3">Question {index + 1}</Label>
                <Colxx sm="9" className="text-left">
                  <Input
                    type="textarea"
                    name="prompt"
                    value={question.prompt}
                    onChange={(e) => handleDetails(e, index)}
                  />
                </Colxx>
              </FormGroup>
              <FormGroup row>
                <Label sm="3">Answer</Label>
                <Colxx sm="9" row>
                  <Row className="text-left flex">
                    <Col md="6">
                      <Input
                        type="text"
                        value={questions[index].answer}
                        onChange={(e) => handleChangeOption(e, index)}
                      />
                    </Col>
                  </Row>
                </Colxx>
              </FormGroup>
            </CardBody>
          </Card>
        ))}

        <Button className="mt-3" onClick={handleAddQuestion}>
          Add Question
        </Button>
      </Colxx>
      <Colxx xxs="3">
        <FormGroup row>
          <Label sm="3">Image</Label>
          <Colxx sm="9">
            <Input
              type="file"
              name="image"
              onChange={handleImage}
              accept="image/*"
              disabled={imageLoading}
            />
          </Colxx>
        </FormGroup>
        {preview && (
          <Col>
            <img alt={"logo"} src={preview} className="card-img-top" />
            {!imageLoading && (
              <p>
                {image.Location} |{" "}
                <button
                  onClick={navigator.clipboard.writeText(`${image.Location}`)}
                >
                  Copy
                </button>
              </p>
            )}
          </Col>
        )}
      </Colxx>
    </>
  );
};

export default MatchingToPictures;
