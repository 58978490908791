const data = [
  {
    id: "courses",
    icon: "iconsminds-check",
    label: "Courses",
    to: `/app/courses`,
    access: ["Admin", "Manager", "User"],
  },
  {
    id: "users",
    icon: "iconsminds-conference",
    label: "Users",
    to: `/app/users`,
    access: ["Admin"],
  },
];
export default data;
