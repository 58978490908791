import React, { useEffect, useState } from "react";
import { Button, Row, Card, Col, FormGroup, Label, Input } from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axiosClient";
import { useToken } from "auth/useToken";
import { useUser } from "auth/useUser";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ConfirmModal from "components/ConfirmModal";

const ModulePage = () => {
  const [data, setData] = useState([]);
  const [title, setTitle] = useState("");
  const [newTask, setNewTask] = useState("");
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [draggable, setDraggable] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [token, setToken] = useToken();
  const user = useUser();
  const location = useLocation();
  const { id } = useParams();
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    axios
      .get(`/api/courses/modules/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        setData(result.data.data);
        setTasks(result.data.data.tasks);
        setTitle(result.data.data.title);
        setLoading(false);
      });
  }, []);

  const navigate = useNavigate();

  const handleAddTask = () => {
    setButtonLoading(true);
    axios
      .post(
        `/api/courses/modules/task`,
        { moduleId: id, title: newTask, author: user.username },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        setTasks([...tasks, result.data.data]);
      });
    setButtonLoading(false);
    setNewTask("");
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newTasks = [...tasks];
    const [reorderedItem] = newTasks.splice(result.source.index, 1);
    newTasks.splice(result.destination.index, 0, reorderedItem);

    setTasks(newTasks);
  };

  const handleDelete = () => {
    setButtonLoading(true);
    axios
      .delete(`/api/courses/modules/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        setButtonLoading(false);
        if (result.status === 200) {
          navigate(-1);
        }
      });
  };
  const handleOrderSubmit = () => {
    setLoading(true);
    axios
      .post(
        `/api/courses/modules/${id}`,
        { tasks },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        setLoading(false);
        setDraggable(false);
        navigate(0);
      });
  };

  const handleUpdate = () => {
    setLoading(true);
    axios
      .post(
        `/api/courses/modules/${id}`,
        { title },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        setLoading(false);
        setDraggable(false);
        navigate(0);
      });
  };

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>Module - {data.title}</h1>
              <Button className="ml-2" onClick={() => setDeleteModal(true)}>
                Delete
              </Button>
              <div className="text-zero top-right-button-container d-flex flex-row">
                <Input
                  type="text"
                  name="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <Button className="ml-2" onClick={(e) => handleUpdate()}>
                  <i className="simple-icon-check" />
                </Button>
              </div>
            </div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {!draggable && (
          <Row>
            <div className="loading" hidden={!loading} />
            {!loading && tasks.length === 0 ? (
              <p>List is empty</p>
            ) : (
              tasks.map((task) => (
                <Colxx xxs="12" key={task._id} className="mb-3">
                  <Card className="d-flex flex-row">
                    <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                      <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                        <NavLink
                          to={`/app/task/${task._id}`}
                          className="w-40 w-sm-100"
                        >
                          <p className="list-item-heading mb-1 truncate">
                            {task.title}
                          </p>
                        </NavLink>
                        <p className="mb-1 text-muted text-small w-15 w-sm-100">
                          {task.author}
                        </p>
                      </div>
                    </div>
                  </Card>
                </Colxx>
              ))
            )}
          </Row>
        )}

        <Row>
          {draggable && (
            <Colxx xxs="12">
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="tasks">
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      xxs="12"
                    >
                      <Colxx xxs="12">
                        {tasks.map((task, index) => (
                          <Draggable
                            key={task._id}
                            draggableId={task._id}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                xxs="12"
                              >
                                <Colxx className="mb-2">
                                  <Card className="d-flex flex-row">
                                    <div className=" d-flex flex-grow-1 min-width-zero">
                                      <div className="m-3 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                                        <p className="mb-0 ml-4">
                                          {task.title}
                                        </p>
                                      </div>
                                    </div>
                                  </Card>
                                </Colxx>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </Colxx>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Colxx>
          )}
        </Row>
        {tasks?.length > 1 && (
          <Row className="mb-2">
            <Col md="9"></Col>
            <Col md="3">
              {!draggable && (
                <Button onClick={() => setDraggable(!draggable)}>
                  Reorder items
                </Button>
              )}
              {draggable && (
                <>
                  <Button
                    onClick={() => handleOrderSubmit()}
                    disabled={buttonLoading}
                    className="ml-2"
                  >
                    Update
                  </Button>
                  <Button
                    onClick={() => setDraggable(!draggable)}
                    className="ml-2"
                  >
                    Cancel
                  </Button>
                </>
              )}
            </Col>
          </Row>
        )}
        <Separator className="mb-5" />
        <Row>
          <Col md="8">
            <FormGroup row>
              <Label sm="3">Task Name</Label>
              <Colxx sm="9">
                <Input
                  type="text"
                  name="newTask"
                  value={newTask}
                  onChange={(e) => setNewTask(e.target.value)}
                />
              </Colxx>
            </FormGroup>
          </Col>
          <Col md="4">
            <Button onClick={handleAddTask} disabled={buttonLoading}>
              Add Task
            </Button>
            <Button
              onClick={() => navigate(-1)}
              disabled={buttonLoading}
              className="ml-2"
            >
              Back
            </Button>
          </Col>
        </Row>

        <ConfirmModal
          modal={deleteModal}
          setModal={setDeleteModal}
          action={handleDelete}
          title={`Are you sure you want to delete?`}
        />
      </div>
    </AppLayout>
  );
};

export default ModulePage;
